import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
export default function LogoutButton() {
    const { logout, isAuthenticated } = useAuth0();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        const accessToken = localStorage.getItem("access_token");
        if (accessToken && isAuthenticated) {
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    }, [isAuthenticated]);
    return <button className="text-white" onClick={() => {
        localStorage.clear();
        logout();
    }}>Logout</button>
}