import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export default function Redirect() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [isError, setIsError] = useState(false);
    useEffect(() => {
        console.log("Redirecting to /");
        const code = searchParams.get('code');
        const state = searchParams.get('state');
        console.log(code, state);
        const callBackend = async () => {
            const response = await fetch(`${process.env.REACT_APP_AUTH_URL}/auth/callback?code=${code}&state=${state}`);
            // console.log(await response.json());//
            const data = await response.json();

            localStorage.setItem("access_token", data.access_token);
            console.log(data.access_token, response.status);

            if (response.status === 200) {
                console.log((response as any).data);
                const org_id_from_token = localStorage.getItem("org_id");
                console.log(org_id_from_token);
                const user_id_from_token = localStorage.getItem("user_id");
                console.log(user_id_from_token);
                if (org_id_from_token && user_id_from_token) {
                    console.log(data);
                    if (org_id_from_token === data.user.org_id && user_id_from_token === data.user.id) {
                        const sim_id = localStorage.getItem("sim_id");
                        const chat_id = localStorage.getItem("chat_id");
                        localStorage.setItem("access_token", data.access_token);
                        if (sim_id && chat_id) {
                            navigate(`/sim/${sim_id}/${chat_id}`);
                        } else {
                            navigate("/");
                        }
                    } else {
                        setIsError(true);
                    }
                }
            } else {
                navigate("/error/401")
            }
        };
        callBackend();
    }, []);
    return (
        <div>
            {isError ? <div className="flex items-center text-gray-500">
                <ErrorOutlineIcon className=" mr-2" />
                <Typography variant="h6" className="text-red-400">
                    The link you are trying to access is either invalid or expired
                </Typography>
            </div> : <div>Wait till you are redirected</div>}
        </div>
    );
};
