import axios from "axios";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
const useSSO = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  let [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const verifyToken = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SIM_BACKEND_HOST}/sim/verify`,
        { headers: { Authorization: token } }
      );
      if (response.data) {
        console.log(response.data);
        localStorage.setItem("sim_id", response.data.public_sim_id);
        localStorage.setItem(
          "chat_id",
          response.data.chat_response[0]?.public_chat_id
        );
        localStorage.setItem("user_id", response.data.user_id);
        localStorage.setItem("org_id", response.data.org_id);
        localStorage.setItem("accessToken", token);
      }
    } catch (error) {
      setError(true);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    verifyToken();
  }, []);

  return { isLoading, error };
};

export default useSSO;
