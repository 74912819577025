import React from "react";
import { useParams } from "react-router-dom";

const Error = () => {
    const { error } = useParams();
    if (error === "401") {
        return <div>Unauthorized access</div>;
    }
    return <div>Error: {error}</div>;
};

export default Error;
